import React from "react";

const FinanceDashboard = React.lazy(() => import('../views/dash/FinanceDashboard'));
const MemberDashboard = React.lazy(() => import('../views/dashboard/DashboardLayout'));
const AttendanceDashboard = React.lazy(() => import('../views/dashboard/attendancedashboard'));
// const Events = React.lazy(() => import('../views/events/events'));
 const MembersContainer = React.lazy(() => import('../views/members/MembersContainer'));
 const GroupContainer = React.lazy(() => import('../views/groups/GroupContainer'));
// const Groups = React.lazy(() => import('../views/groups/groups'));
// const AddGroup = React.lazy(() => import('../views/groups/GroupForms'));

const routes = [
    { path: "/financedashboard", component: FinanceDashboard },
    { path: "/memberdashboard", component: MemberDashboard },
    { path: "/attendancedashboard", component: AttendanceDashboard },
    // { path: "/events", component: Events },
     {path: "/members", component: MembersContainer },
     { path: "/groups", component: GroupContainer },
    // {path: "/add_group", component: AddGroup },
]
export default routes;