import React, { Suspense, useEffect } from 'react';
import routes from './routes';
import { HashRouter, Link, Route, Routes, Switch, Navigate, BrowserRouter } from 'react-router-dom';
//import { Sidebar } from "./Includes";
import AutoLogout from './AutoLogout';
import Sidebar from './Sidebar';
import Header from './Header';

const loader = () => { <div>Loading...</div> };

const DefaultLayout = () => {

  useEffect(() => {
  }, [])

  return (

    <main className="overflow-hidden pr-4 pb-6 bg-slate-50">
      <div className="flex gap-5 max-md:flex-col">
        <Sidebar />
        <div className="flex flex-col ml-5 w-[84%] max-md:ml-0 max-md:w-full">
          <section className="flex overflow-hidden flex-col px-6 py-5 mx-auto mt-4 w-full bg-white rounded-2xl border border-solid border-slate-100 max-md:px-5 max-md:mt-10 max-md:max-w-full">
         
            <Suspense fallback={loader}>
              {/* <Routes> */}
              {/* <Switch>
                {routes.map((route, index) => {
                  const Component = route.component;
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={<Component />}
                    />
                  );
                })}
              </Switch> */}

              <Suspense fallback={loader}>

                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />
                    ) : (null);
                  })}

                </Switch>
              </Suspense>
              {/* </Routes> */}
            </Suspense>


          </section>
        </div>
      </div>
    </main>


  )
};


export default DefaultLayout;

{/*
   <SearchControls />
            <TabSelector />
            <MemberTable />
            <Pagination />
  
  <div className="container-fluid login-bgcolor App-header">
      <div class="row">
        <AutoLogout />
        <Sidebar />

        <div class="col-sm-10 p-2 mt-4 mr-4" id="main">
          
        </div>
      </div>
    </div> */}