import React from "react";
import { Logout } from "./Config";

export default function Header() {
  return (
    <header className="flex flex-wrap gap-10 justify-between items-center w-full max-md:max-w-full">
      <div className="flex gap-1 items-center self-stretch my-auto text-sm font-medium leading-none whitespace-nowrap text-slate-600">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ebbade219288bb31c68a316fd0142f36a4e10adca8b47cfcfd1787439195616?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375"
          className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
          alt="Members section icon"
        />
        <h1 className="self-stretch my-auto">Members</h1>
      </div>
      <div className="flex gap-3 items-center self-stretch my-auto">
        <button aria-label="View notifications" className="relative" onClick={()=>Logout()}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2750d47f-36d2-4cc6-b786-23d256c9e172?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375"
            className="object-contain shrink-0 self-stretch my-auto w-6 rounded-md aspect-square"
            alt=""
          />
        </button>
        <div className="flex gap-3 items-center self-stretch my-auto">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca6b9d2770db7523a67864160ef25126e19223c788e411ec83ecb4a72f65d6e5?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375"
            className="object-contain shrink-0 self-stretch my-auto w-8 aspect-square rounded-[60px]"
            alt="User profile"
          />
          <div className="flex flex-col justify-center self-stretch my-auto">
            <div className="text-sm font-semibold tracking-tight text-slate-900">
              Hi, Selasie
            </div>
            <div className="gap-0.5 self-stretch text-xs leading-none text-slate-400">
              Church Admin
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
