import React, { useState, useEffect } from 'react';
import { DoLogin, DConnect, PostOptions2 } from '../../components/Config';
import { Link, useLocation, useHistory } from 'react-router-dom';
import axios from "axios";
import PoweredBy from "./PoweredBy";
import Logo from "./Logo";
import FormInput from "./FormInput";
import  Button  from '../../components/Button';

export default function AuthLayout() {

  const [formItems, setformItems] = useState({ username: '', password: '' });
  const [userEmail, setUserEmail] = useState({ user_email: '' });
  const [screenNo, setScreenNo] = useState(1);
  const { search } = useLocation();
  const history = useHistory();
  const [resetData, setResetData] = useState({
    "user_id": 0,
    "user_email": "",
    "reset_code": "",
    "new_password": "",
    "otp_value": ""
  });



  useEffect(() => {
    const code = new URLSearchParams(search).get("code")
    if (code !== null) {
      verifyResetCode(code);
    }
  }, [search])

  const submitForm = (e) => {
    e.preventDefault();
    DoLogin(formItems);

  };

  const verifyResetCode = (resetCode) => {
    setScreenNo(4);
    DConnect().get(`users/noauth/verify_password_reset/${resetCode}/`).then(res => {
      let { user } = res.data;
      let userData = JSON.parse(user);
      setResetData({
        "user_id": userData?.user_id,
        "user_email": userData?.user_email,
        "reset_code": resetCode,
        "new_password": "",
        "confirm_password": "",
        "otp_value": ""
      });
      history.replace("/");

    }).catch((error) => alert(`Password Verification: ${error.message}`));

  };

  const submitForm2 = (e) => {
    e.preventDefault();
    axios(PostOptions2("POST", userEmail, "users/noauth/attempt_password_reset/"))
      .then((res) => {
        let { status } = res.data;
        if (status.toLowerCase() === 'successful')
          setScreenNo(3)
      }).catch((error) => alert(`Password Reset Link: ${error.message}`));

  };

  const submitForm3 = (e) => {
    e.preventDefault();
    delete resetData['confirm_password'];
    axios(PostOptions2("POST", resetData, "users/noauth/reset_password/"))
      .then((res) => {
        let { user_uuid } = res.data;
        if (user_uuid !== undefined || user_uuid !== null) {
          setScreenNo(5)
        }


      }).catch((error) => alert(`Password Reset: ${error.message}`));

  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setformItems(prevState => ({ ...prevState, [name]: value }));
  };

  const handleResetChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    setResetData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleEmailChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    setUserEmail(prevState => ({ ...prevState, [name]: value }));
  };



  return (
    <main className="overflow-hidden bg-slate-50 h-screen">
      <div className="flex flex-col lg:flex-row gap-5 h-full">
        {/* Left Section */}
        <section className="relative flex flex-col w-full lg:w-2/3 h-full">
          <div className="flex flex-col grow w-full rounded-2xl bg-stone-300 h-full">
            <div className="relative flex flex-col items-center px-5 py-10 w-full h-full rounded-2xl">
              {/* Background Image */}
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/bccd1b3a86d6fceb82504eca845c19d153b5fa1c69f739fd3e491470199c1252?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375"
                className="object-cover absolute inset-0 w-full h-full rounded-2xl"
                alt=""
                role="presentation"
              />
              {/* Company Logo */}
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3420150f13d3e29e7bdb37ab2b5b9a7e6f91861fab36b7c45b72cb4a98521202?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375"
                className="absolute top-5 left-5 object-contain max-w-full aspect-[1.02] rounded-full w-16 lg:w-20"
                alt="Company logo"
              />
            </div>
          </div>
        </section>

        {/* Right Section */}
        <section className="flex flex-col w-full lg:w-1/3 h-full">
          <div className="flex flex-col justify-center w-full rounded-2xl bg-slate-100 p-5 h-full">

            {(screenNo == 1) && <form
              onSubmit={submitForm}
              className="flex overflow-hidden flex-col items-center px-5 py-7 w-full bg-white rounded-2xl border-solid border-[0.825px] border-slate-100 max-w-[600px]"
            >
              <div className="flex flex-col items-center w-full text-center">
                <Logo />
                <div className="flex flex-col items-center mt-3.5 w-full">
                  <h1 className="text-2xl font-semibold tracking-tight leading-none text-slate-900">
                    Sign in as Admin
                  </h1>
                  <p className="mt-2 text-base tracking-tight text-slate-500">
                    Welcome back! Please sign in to continue
                  </p>
                </div>
              </div>
              <div className="flex flex-col self-stretch mt-7 w-full">
                <div className="flex flex-col w-full ">
                  <FormInput
                    onChange={handleChange}
                    label="Email address"
                    type="email"
                    name="username"
                    value={formItems.username}
                    placeholder="Enter your email address"
                    required
                    style="max-w-[600px]"
                  />
                  <FormInput
                    label="Password"
                    type="password"
                    name="password"
                    value={formItems.password}
                    placeholder="Enter your password"
                    required
                    onChange={handleChange}
                    showPasswordToggle
                  />
                </div>
                <button
                  onClick={() => setScreenNo(2)}
                  type="button"
                  className="gap-2 self-stretch mt-3.5 w-full text-sm font-semibold text-purple-800 underline decoration-auto decoration-solid underline-offset-auto"
                >
                  Forgot password?
                </button>
              </div>
              <button
                type="submit"
                className="overflow-hidden gap-2.5 self-stretch px-3.5 py-2 mt-7 w-full text-base font-semibold text-white rounded-md shadow-sm bg-purple-950"
              >
                Sign in
              </button>
            </form>}

            {(screenNo == 2) && <form onSubmit={submitForm2}
              className="flex flex-col items-center px-5 py-7 w-full max-w-md bg-white rounded-2xl border-solid shadow-lg border-[0.825px] border-slate-100"
              aria-labelledby="reset-password-title"
            >
              <div className="flex flex-col items-center self-stretch w-full text-center">
                <div className="flex flex-col items-center max-w-full w-[290px]">
                  <h1 id="reset-password-title" className="text-xl font-semibold tracking-tight leading-none text-slate-900">
                    Reset your Password
                  </h1>
                  <p className="mt-2 text-sm tracking-tight leading-5 text-slate-500">
                    Please enter your email to get the password reset link sent to you.
                  </p>
                </div>
              </div>

              <FormInput
                label="Email address"
                type="email"
                ame="user_email"
                placeholder="Enter your email address"
                onChange={handleEmailChange}
                required
              />

              <button
                type="button"
                onClick={() => setScreenNo(1)}
                className="mt-7 text-sm font-semibold tracking-normal leading-none text-purple-800 underline decoration-auto decoration-solid underline-offset-auto"
              >
                Wait, I remember my password
              </button>

              <div className="w-full flex justify-center mt-4">
                <Button
                  type="submit"
                  className="w-full max-w-xs px-4 py-2 text-base font-semibold text-white bg-purple-950 rounded-md shadow-sm"
                >
                  Send Link
                </Button>
              </div>
            </form>
            }


            {(screenNo == 3) &&

              <div className="flex overflow-hidden flex-col px-5 py-7 w-full bg-white rounded-2xl border-solid shadow-lg border-[0.825px] border-slate-100">
                <div className="flex overflow-hidden flex-col px-7 py-11 w-full rounded-lg bg-slate-50 max-md:px-5">
                  <div className="self-end py-2 pr-2 pl-2 mr-9 text-xs font-semibold tracking-wide leading-none text-center text-white uppercase bg-purple-800 rounded-[37.552px] max-md:mr-2.5">
                    1 new message
                  </div>
                  <div
                    className="gap-2 self-stretch px-2 py-2 mt-14 text-xs tracking-normal leading-9 bg-white rounded-[33px] shadow-[0px_0px_19px_rgba(0,0,0,0.05)] text-purple-950 max-md:mt-10 break-all"
                  >
                    https://VictoryBible ChurchInternational/accountreset+link&oq=fake+link+&gs_=UTF-8
                  </div>
                </div>
                <div className="flex flex-col mt-7 w-full" role="alert" aria-live="polite">
                  <h1 className="self-center text-xl font-semibold tracking-tight leading-none text-slate-900">
                    Password reset link has been sent
                  </h1>
                  <p className="mt-3 text-sm tracking-normal leading-5 text-center text-slate-600">
                    Thank you.
                    <br />
                    If an account exists with your email address, you should
                    receive an email with a link to reset your password.
                  </p>
                </div>
              </div>

            }
            {(screenNo == 4) &&

              <form onSubmit={submitForm3} className="flex flex-col px-5 py-7 w-full bg-white rounded-2xl border-solid shadow-lg border-[0.825px] border-slate-100">
                <div className="flex flex-col w-full text-center">
                  <h1 className="self-center text-xl font-semibold tracking-tight leading-none text-slate-900">
                    Create a new password
                  </h1>
                  <p className="mt-3 text-sm tracking-normal leading-none text-slate-600">
                    Enter a new password to secure your account.
                  </p>
                </div>

                <div className="flex flex-col items-start mt-7 w-full text-sm">
                  <FormInput
                    label="New Password"
                    type="password"
                    name="new_password"
                    value={formItems.new_password}
                    placeholder="Enter your password"
                    required
                    onChange={handleResetChange}
                    showPasswordToggle
                  />
                  <small className="text-secondary" style={{ fontSize: '10px' }}>Password should be at least 8 characters long.</small>

                  <FormInput
                    label="Confirm Password"
                    type="password"
                    name="confirm_password"
                    value={formItems.confirm_password}
                    placeholder="Enter your password"
                    required
                    onChange={handleResetChange}
                    showPasswordToggle
                  />
                  <small className={resetData.new_password === resetData.confirm_password ? "text-secondary" : "text-danger"} style={{ fontSize: '10px' }}>This should be the same as the password inputed above.</small>
                </div>

                <button
                  type="submit"
                  className="flex-1 shrink gap-2 self-center px-3.5 py-2.5 mt-7 max-w-full text-sm font-medium text-center text-white rounded-lg shadow-sm bg-purple-950 w-[260px]"
                >
                  Create new password
                </button>
              </form>
            }
            {(screenNo == 5) &&

              <div className="flex overflow-hidden flex-col px-5 py-7 w-full bg-white rounded-2xl border-solid shadow-lg border-[0.825px] border-slate-100">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/297c2b564997decf05cdc63408f6e5225aa98305d47ba5ecf7433b02968c1849?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375"
                  className="object-contain self-center rounded-none aspect-square w-[88px]"
                  alt="Success checkmark"
                />
                <div className="flex flex-col mt-3 w-full">
                  <div className="flex flex-col w-full">
                    <h1 className="self-center text-xl font-semibold tracking-tight leading-none text-slate-900">
                      Password creation success
                    </h1>
                    <p className="mt-2 text-sm tracking-normal leading-none text-slate-600">
                      Your new password has been saved
                    </p>
                  </div>
                  <button
                    className="flex-1 shrink gap-2 self-center px-3.5 py-2.5 mt-4 max-w-full text-sm font-medium text-white rounded-lg shadow-sm bg-purple-950 w-[260px]"
                    type="button"
                  >
                    Go to login
                  </button>
                </div>
              </div>

            }


            <PoweredBy />
          </div>
        </section>
      </div>
    </main>
  );
}
