import React from "react";

export default function Logo() {
  return (
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e72944bfc28baf68cfc0b605dbe3d6a26d06294ecb0aaa1981bf8a3242427274?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375"
      className="object-contain max-w-full aspect-square w-[125px]"
      alt="Application logo"
    />
  );
}
