import React from "react";
import SidebarItem from "./SidebarItem";

export default function Sidebar() {
  const menuItems = [
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/767f3e4458d2e6ad790b2118a427daab15b3a041a107fd86adf5db707ef5aaa2?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375",link:"/financedashboard", label: "Dashboard", hasDropdown: true, },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8434d5bc909c12258f8f65ef5e0ac2d464fadd0aeb12ec059d00b89c499e7efb?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375",link:"/members", label: "Members",active: true  },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d2d3c50e4151a410f2da883455a1367cacfef6ab6b0b600c02e0b2ef3936ff90?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375",link:"/groups", label: "Groups" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8223a40cd1fb09db2df442127dad371da23711e5280bcbe7fe245922f28f6eef?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375",label: "Events" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2bec97ee983489e07c7fe96d01d9d5b6e9a8fbd8e1e54531498474edf90c90d9?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375",label: "Communication" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/52a1b18310b346483045f12d970911ec83be8269b86a30322cd52e7cf4629c32?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375",label: "Finances" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/faeb7d07b67b8afa082d173f0282a054b6af19c8869260aab89c28ba753506c8?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375",label: "Attendance" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ecb9a9b8c7a32671bc53af82080bc5fb2ef28cc87a00c6286816849455be7e93?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375", label: "Follow Up" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/60696ce1a46ae77828d000853238f2ba095864d69085512f00e20f08b424c90a?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375", label: "Media Center" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/062ea75e222b737b50559e15fdd845b556299a6aabc9d4fcc30f2320a1d4267f?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375", label: "Document Center" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/22359533970fec4af8a81e95366bf0c4a0744e1a99db1c4130fb0558d04542fa?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375", label: "Approvals" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3fc29715b6206e7614efba77b29a25621fd3460a534a06feb8a385f9e288ae00?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375", label: "User Management" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f0c6d46f0351546264e888ae23ad54f6355cf81d3cef781ed98803ad8cd1390f?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375", label: "Surveys and Forms" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ed6615796dc7b5783277bc878e0ae7be8394ee9f458edf2797ea3974ff3448c9?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375", label: "Reports" },
    { label: "Bank Setup" },
  ];

  return (
    <aside className="flex flex-col w-[16%] max-md:ml-0 max-md:w-full">
      <nav
        className="flex flex-col min-h-[658px] max-md:mt-6"
        role="navigation"
        aria-label="Main navigation"
      >
        <div className="flex flex-col flex-1 w-full">
          <div className="flex gap-2.5 justify-center items-center self-center py-5 w-[63px]">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/21cfb4a5a94354ac8cfa54492081644c83763cdf4131f2f661429f8435376c45?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375"
              className="object-contain self-stretch my-auto aspect-square rounded-[48px] w-[63px]"
              alt="Church management system logo"
            />
          </div>
          <div className="flex flex-col pl-6 mt-1 w-full text-sm font-medium leading-none h-[571px] text-slate-600 max-md:pl-5">
            <div className="flex flex-col w-full">
              {menuItems.map((item, index) => (
                <SidebarItem key={index} {...item} />
              ))}
            </div>
          </div>
        </div>
      </nav>
    </aside>
  );
}
