import React, { useState } from "react";

export default function FormInput({
  label,
  type,
  placeholder,
  required,
  onChange,
  name,
  showPasswordToggle,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const inputId = `${label.toLowerCase().replace(/\s/g, "-")}-input`;
  const inputType = showPassword ? "text" : type;

  return (
    <div className="flex flex-col mt-6 w-full max-w-[360px]">
      <label
        htmlFor={inputId}
        className="w-full text-sm font-medium tracking-tight text-slate-600"
      >
        {label}
      </label>
      <div className="flex justify-between items-center px-2.5 py-2.5 mt-2.5 w-full text-base rounded-lg border-solid bg-slate-50 border-[0.825px] border-slate-200 text-slate-400">
        <input
          type={inputType}
          id={inputId}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          className="flex-1 bg-transparent border-none outline-none"
          aria-label={label}
        />
        {showPasswordToggle && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={showPassword ? "Hide password" : "Show password"}
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/dae66976b60f16d8ab0bbb40a3d8b76ad6ede0f7808b5ef482af053ba6a83336?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375"
              className="object-contain shrink-0 self-stretch my-auto aspect-[0.95] w-[19px]"
              alt=""
              role="presentation"
            />
          </button>
        )}
      </div>
    </div>
  );
}
