import React,{Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Suspense fallback={(<div id="loader"></div>)}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
       
      </BrowserRouter>
    </React.StrictMode></Suspense>

  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
