import qs from 'qs';
import axios from "axios";

const DoLogin = (formItems) => {
    axios(PostOptions("POST", formItems, "users/login_for_token/"))
        .then((res) => {
            let user = res.data;
            setToken(user.access_token);
            setUser(user);
            window.location.reload()
        }).catch((error) => alert(`Authentication: ${error.message}`));
}

const Logout = () => {
    sessionStorage.removeItem('faithHQ_user');
    sessionStorage.removeItem('faithHQ_token');
    window.location.reload()
}

const GetCurl = (location) => {
    let loc = location.hash.split("/");
    return loc;
};

const LoggedIn = () => getUser() !== null;

const setUser = (user) => sessionStorage.setItem('faithHQ_user', JSON.stringify(user));
const getUser = () => JSON.parse(sessionStorage.getItem('faithHQ_user'));

const setCookie = (name, value) => sessionStorage.setItem(name, value);
const getCookie = (name) => sessionStorage.getItem(name);

const setToken = (token) => sessionStorage.setItem("faithHQ_token", token);
const getToken = () => sessionStorage.getItem("faithHQ_token");

const IsAuthenticated = () => LoggedIn();

const DConnect = () => {
    return axios.create({
        baseURL: BaseURL() + "v1.0/",
        timeout: 60000,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
        },
    });
};


const getEndpoint=()=>{
    return axios.create({
        baseURL: BaseURL() + "api/",
        timeout: 60000,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
        },
    });

}

const PostOptions = (meth, formBody, area) => {
    let formData = qs.stringify(formBody);
    return {
        method: meth,
        url: BaseURL() + "userapps/v1.0/" + area,
        data: formData,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: `Bearer ${getToken()}`,
        },
    };
};

const PostOptionBackend = (meth, formBody, area) => {
    let formData = JSON.stringify(formBody);
    return {
        method: meth,
        url: BaseURL() + "api/" + area,
        data: formData,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getToken()}`,
        },
    };
};

const PostOptions2 = (meth, formBody, area) => {
    let formData = JSON.stringify(formBody);
    return {
        method: meth,
        url: BaseURL() + "userapps/v1.0/" + area,
        data: formData,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getToken()}`,
        },
    };
};
const BaseURL = () => {

    //let serverURL = "http://localhost:3000/";
    let serverURL = "https://api-faithhq-staging.meshapps.io/";


    return serverURL;

};

const Config = () => {

}

Config.DoLogin = DoLogin;
Config.Logout = Logout;
Config.LoggedIn = LoggedIn;
Config.setUser = setUser;
Config.getUser = getUser;
Config.GetCurl = GetCurl;
Config.IsAuthenticated = IsAuthenticated
Config.setCookie = setCookie;
Config.getCookie = getCookie;
Config.setToken = setToken;
Config.getToken = getToken;
Config.DConnect = DConnect;
Config.PostOptions = PostOptions;
Config.PostOptions2 = PostOptions2;
Config.BaseURL = BaseURL;
Config.PostOptionBackend=PostOptionBackend
Config.getEndpoint=getEndpoint

export default Config;
export {
    DoLogin, Logout, LoggedIn, GetCurl, setUser, getUser, setToken, getToken,
    IsAuthenticated, setCookie, getCookie, DConnect, PostOptions, PostOptions2, BaseURL,PostOptionBackend,getEndpoint
}