import React from "react";

export default function PoweredBy() {
  return (
    <div className="flex gap-2.5 justify-center items-center px-2.5 py-3 w-full text-sm tracking-tight leading-6 text-center rounded-none bg-slate-100 text-slate-400">
      <span className="self-stretch my-auto">Powered by</span>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/abcfd10513d81351761fae344b69b05e4b7ba7bd245b34753d8478b3914fd6f9?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375"
        className="object-contain shrink-0 self-stretch my-auto aspect-[3.17] w-[89px]"
        alt="Company brand"
      />
    </div>
  );
}
