import React from "react";
import { Link } from "react-router-dom";

export default function SidebarItem({ icon, link, label, active, hasDropdown }) {

  const baseClasses =
    "flex gap-2.5 items-center px-3 py-2.5 mt-1 w-full min-h-[38px] ";
  const activeClasses = active
    ? "text-base font-semibold text-white bg-purple-800 rounded-md border border-solid border-purple-950"
    : " ";

  return (
    <>
    <Link
      to={link}
      className={`${baseClasses} ${activeClasses}`}
      aria-current={active ? "page" : undefined}
      role="menuitem"
      aria-expanded={hasDropdown ? "false" : undefined}

    >
      <div className="flex gap-2.5 items-center self-stretch my-auto">
        {icon && (
          <img
            src={icon}
            className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
            alt=""
            aria-hidden="true"
          />
        )}
        <span className="self-stretch my-auto">{label}</span>
      </div>
      {hasDropdown && (
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/dde0167cf51cd6bc0e9ecf28da603eb741f26073bef4b529d46028feff19b5ff?placeholderIfAbsent=true&apiKey=f43be33ce1904855bba37adf8be95375"
          className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square ml-auto"
          alt=""
          aria-hidden="true"
        />
      )}
    </Link>
    </>
  );
}
