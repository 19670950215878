import * as React from "react";

function Button({ variant = "default", children, onClick }) {
  const baseStyles = "flex overflow-hidden justify-center items-center rounded-lg shadow-sm min-h-[30px]";
  const variantStyles = {
    default: "border border-solid border-slate-300 text-slate-600 bg-white",
    primary: "bg-green-600 text-white"
  };

  return (
    <button 
        
      className={`${baseStyles} ${variantStyles[variant]}`}
      onClick={onClick}
      type="button"
    >
      <div className="gap-2 self-stretch px-4 py-3 my-auto shadow-sm min-h-[38px]">
        {children}
      </div>
    </button>
  );
}

export default Button;